// https://flow.skoda-brand.com/d/6aQMrmD6PhWL/web#/components/label/anatomy
// styleguide/Labels


.label {
    display: inline-block;
    transition: background-color .3s ease;
    z-index: 5;
    white-space: nowrap;

    background-color: $color-on-surface500;
    color: $color-text-white;
    border-radius: 2px;
    padding: 5px 10px;
    font-size: 11px;
    line-height: 12px;
    font-weight: bold;
    letter-spacing: 3px;
    
    &:hover {
        background-color: $color-on-surface400;
        color: $color-text-white;
    }

    &--general {
        background-color: transparent;
        color: $color-on-surface500;
        text-transform: uppercase;
        font-size: 11px;
        line-height: 12px;
        font-weight: bold;
        letter-spacing: 3px;

    }

    &--green, &--feature, &--primary {
        background-color: $color-tertiary;
        color: $color-text-white;
        border-radius: 2px;
        padding: 5px 10px;
        font-size: 11px;
        line-height: 12px;
        font-weight: bold;
        letter-spacing: 3px;
    }

    &--neutral, &--feature2, &--secondary {
        background-color: $color-on-surface500;
        color: $color-text-white;
        border-radius: 2px;
        padding: 5px 10px;
        font-size: 11px;
        line-height: 12px;
        font-weight: bold;
        letter-spacing: 3px;
        
        &:hover {
            background-color: $color-on-surface400;
            color: $color-text-white;
        }
    }

    &--stoerer, &--promotional {
        color: $color-secondary;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 12px;
        border-radius: 3px;
        overflow: hidden;
        white-space: nowrap;

        &.small {

            .label__label {
                font-size: 12px;
                padding: 8px 10px 8px 16px;
                font-weight: 700;
                line-height: 12px;
            }
        }

        > div {
            transform: skew(-21deg, 0deg);
            border-radius: 3px;
            margin: 0px 6px 0px -6px;
            background-color: $color-tertiary;
        }

        .label__label {
            transform: skew(21deg, 0deg);
            padding: 10px 14px 10px 20px;
        }
    }

    &--status {
        
    }
}


// Find it and remove it (Allgemeines Styling, nicht relevant in dieser Komponente)
.label__list {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem
}