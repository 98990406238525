$columns: 12; // Number of columns in the grid system

.container {
	display: flex;
	margin: 0 auto;
	flex-wrap: wrap;
    max-width: 1180px;
	width: 100%;
    //min-height: calc(100% - $footer-height);
	padding: 1rem;

	@media screen and (max-width: 992px) {
		padding: 1rem;
	}
}



@for $width from 1 through $columns {
	.col-#{$width} {
		flex-basis: calc($width / $columns) * 100%;
	}  
}


.container-fluid {
    //min-height: calc(100% - $footer-height); 
	max-width: calc(100% - 6rem);
    margin: auto;
}
