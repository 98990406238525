.tile {
    //border-radius: .5rem;
    cursor: pointer;
    //min-width: 200px;
    //min-height: 120px;
    width: 100%;
    transition: scale .1s ease;
    display: block;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background: $color-surface-white;
    position: relative;
    //aspect-ratio: 16 / 9;
    color: $color-text-primary;

    &:hover {
        outline: 1px solid $color-primary;
    }

    &:active {
        scale: .99;
    }

    &--rounded {
        border-radius: .25rem;
    }

    &--p1 {
        padding: 1rem;
    }

    &--p2 {
        padding: 2rem;
    }

    &--p3 {
        padding: 3rem;
        
        @include devices(mobile) {
            padding: 2rem;
        }
    }

    &--bg-grey, &--grey {
        background-color: $color-surface-primary;
    }

    &--bg-green {
        background-color: $color-tertiary;
        color: $color-text-white;

        &:hover {
            background-color: $color-primary;
            color: $color-text-white;
        }
    }

    &--center {
        display: grid;
        place-items: center;

    }

    &--h100 {
        height: 100%;
    }


    &__wrapper {
        height: 100%;
        width: 100%;
        position: relative;
    }

    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(61, 61, 61, 0.4);
        border-radius: .5rem;

        ~ .tile__heading {
            color: $color-text-white;
        }
    }

    &__heading {
        font-size: 1rem;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        line-height: 1;

        .label {
           display: inline-block;
           margin-left: .5rem;
        }
    }

    &__subtitle {
        margin-bottom: .25rem;
        font-size: .75rem;
    }

    &__title {
        font-weight: bold;
        line-height: 1;
        margin: 0;
        color: $color-text-primary;
    }

    &__progress {
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        right: .75rem;
        bottom: .75rem;
        display: block;

        $progress-bar-stroke-width: 4;
        $progress-bar-size: 2rem;

        
        svg {
            transform: rotate(-90deg);

            circle{
                stroke: $color-on-surface25;
            }
        }
        
        .percent {
            stroke-dasharray: 100;
            stroke-dashoffset: calc(100px - var(--progress));
            transition: stroke-dashoffset 1s ease;
            -webkit-text-stroke-color: $color-primary;
            stroke: $color-primary;
        }

        .sa-i {
            width: 100%;
            height: 100%;
            background-color: $color-primary;
            color: $color-text-white;
            border-radius: 100%;
            font-size: 1rem;
            display: block;
            line-height: 1.4rem;
            text-align: center;
        }
    }

    &__date {
        position: absolute;
        display: none;
        top: 1rem; 
        right: 1rem;
    }

    &__image {
        max-width: 100%;
        width: 80%;
        margin: auto;
    }

    &__stoerer {
        position: absolute;
        top: 0;
        left: -.75rem;
        padding: .25rem 1rem .25rem 1.5rem !important;
        font-size: .8rem;
        font-weight: normal !important;
        -webkit-clip-path: unset !important;
                clip-path: unset !important;
        transform: skewX(-21deg);
        border-radius: 2px;

        p {
            transform: skewX(21deg);
            margin: 0;
        }
    }

    &.is-clicked {
        color: $color-text-white;
        background: $color-primary;
    }



    &--module-car {
        
        aspect-ratio: 3 / 2;
        transition: background-color .2s ease;

        .tile__back {
            max-width: 100%;
            width: 80%;
            margin: auto;
            display: none;
            height: 80%;
            -o-object-fit: contain;
               object-fit: contain;
           
        }

        .tile__image {
            height: 80%;
            -o-object-fit: contain;
               object-fit: contain;
        }

        &:hover {
            background-color: $color-surface-secondary;

            .tile__image {
                display: none;
            }

            .tile__back {
                display: block;
            }

            .tile__progress {
                //display: block;
                svg {
                    stroke: $color-on-surface0;
                }
               
            }

            .tile__date {
                display: block;
            }
        }
    }
    
    &--module {
        aspect-ratio: 3 / 2;  
        transition: background-color .2s ease;

        .tile {
            &__image {
                max-width: 100%;
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                   object-fit: cover;
                margin: auto;
            }
           
        }

        &:hover {
            background-color: $color-surface-secondary;
              
            .tile__progress {
                display: block;
            }

            .tile__date {
                display: block;
            }
           
        }
    }

    &--image {

        .tile {
            &__title {
                color: $color-text-white;

            }

            &__overlay{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(48,48,48,1) 0%, rgba(57,57,57,0) 50%);;
            }

            &__heading {
                z-index: 1;
                color: $color-text-white;
            }
        }
    }

}

::root {
    --progress: 0;
  }
  