.learning_topic {
    
    &__intro {
        max-height: 50vh;
        height: 50vh;
        overflow: hidden;
        display: flex;
        justify-content: start;
        align-items: center;
        position: relative;

        &--end {
            justify-content: end;
            text-align: end;
        }
    }

    &__headline {
        color: $color-text-white;
        padding: 3rem;
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

}