.vorwort {
    max-width: 820px;


    &__image {
        //height: 250px;
        -o-object-fit: cover;
           object-fit: cover;
        margin-bottom: 2rem;
    }
}