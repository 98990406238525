// Nur der container für Kartenartige Elemente

.card {
    
    background-color: $color-background-primary;
    transition: all 350ms ease;
    display: block;
    //overflow: hidden;
    border: 0;
    border-radius: 0;

    &--p1 {
        padding: 1rem;
    }

    &--p2 {
        padding: 2rem;
    }

    &--p3 {
        padding: 3rem;
    }

    &--p4 {
        padding: 4rem;
    }

    &--p5 {
        padding: 5rem;
    }

    &--p6 {
        padding: 6rem;
    }

    &--rounded {
        border-radius: 10px !important;
    }

    &--shadow {
        box-shadow: 0 0 .5rem $color-transparent-backdrop;
    }

    &--transparent {
        background-color: rgba($color-background-primary, 0.9);
    }

    &--blur {
        background-color: rgba($color-background-primary, 0.8);
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
    }

    &--hovershadow {
        &:hover{
            box-shadow: 0 0 .5rem $color-surface-secondary;
        }
    }

    &--green {
        background-color: $color-tertiary;
        color: $color-text-white;
    }

    &--grey {
        background-color: $color-surface-primary;
    }

    &--50 {
        padding: 6rem 25%;
    }

    &--h100 {
        height: 100%;

    }

    &--centered {
        margin: auto;
    }

    
    &__content {
        padding: 3rem;
        display: flex;
        flex-direction: column;
        align-items: start;

        &--p0 {
            padding: 0;
        }

        &--p1 {
            padding: 1rem;
        }

        &--p2 {
            padding: 2rem;
        }

        &--small {
            width: 60%; 
            margin: auto;

            @include devices(mobile) {
                width: 100%;
            }
        }

        &--center {
            justify-content: center;

            .text-aleft {
                text-align: left;
            }
        }

        &--vcenter {
            align-items: center;
            text-align: center;
            
            .text-aleft {
                text-align: left;
            }
        }

        &--h100 {
            height: 100%;
        }

        img {
            max-width: 100%;
        }
    }


    @include devices(tablet) {
        &--p3 {
            padding: 3rem 1.5rem;
        }

        &--p4 {
            padding: 3rem 1.5rem;
        }

        &__content {
            padding: 1rem;
        }
    }
}




.card {
    &--login {
        margin: auto;
        max-width: 30rem;

        &-left {
            margin-left: 0;
            margin-right: auto;
        }

        &-right {
            margin-right: 0;
            margin-left: auto;
        }
        
    }

    input[type=password] {
        font-family: sans-serif;
    }
}