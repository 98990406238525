.p-1 {
    padding: 1rem;
}

.p-2 {
    padding: 2rem;
}

.p-3 {
    padding: 3rem;
}

.p-4 {
    padding: 4rem;
}

.p-40 {
    padding: 40px;
}

.p-60 {
    padding: 60px;
}


.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

.mb-5 {
    margin-bottom: 5rem;
}

.mb-6 {
    margin-bottom: 6rem;
}

.mb-30, .mb-md {
    margin-bottom: map-get($spacers, md);
}

.mb-48, .mb-45, .mb-lg {
    margin-bottom: map-get($spacers, lg);
}

.mb-60, .mb-xl {
    margin-bottom: map-get($spacers, xl);
}

.mt-10, .mt-sm {
    margin-top: map-get($spacers, sm);
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mt-4 {
    margin-top: 4rem;
}

.mt-5 {
    margin-top: 5rem;
}

.pt-nav {
    padding-top: $nav-height;
}

.gx-10 {
    --bs-gutter-x: $gutter-xl;
} 

.gy-10 {
    --bs-gutter-y: $gutter-xl;
}


.center {
    &__vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.center_img {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    img  {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
  }

.center_something {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  
    > * {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
}

.position-sticky {
    position: -webkit-sticky;
    position: sticky;
}
    