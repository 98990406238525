.accordion {
    * {
        font-family: $font-family-skoda-next;
    }

    &--nooutsidelines {

        .accordion__item {
            &:first-child {
                border-top: none;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }

    &--p0 {
        
        .accordion {
            &__button {
                padding: 19px 0;
            }

            &__content {
                padding: 4px 2px 22px 2px;
            }
        }
    }

    &__item{
        border-bottom: 1px solid $color-on-surface100;

        &:first-child{
            border-top: 1px solid $color-on-surface100;
        }

        // &:has(.accordion__panel:not(.collapsed)) .accordion__button:after {
        //     transform: rotate(45deg);
        // }

        &.collapsed .accordion__button:after {
            transform: rotate(0deg);
        }

        &--disabled {
            .accordion__button:after {
                display: none;
            }

            .accordion__panel {
                display: none;
            }
        }
    }

    &__button {
        cursor: pointer;
        width: 100%;
        border: 0;
        text-align: left;
        outline: none;
        transition: 0.4s;
        padding: 19px 19px;
        //background-color: $color-on-surface0;
        background-color: transparent;
        font-weight: 700;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        * {
            // font-weight: 700;
            // font-size: 1rem;
        }

        &:hover {

            &::after {
                background-color: $color-on-surface50;
            }
        }
        
        &:after {
            content: '\002B';
            border-radius: 100px;
            color: $color-text-primary;
            font-weight: 400;
            font-size: 24px;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            transform: rotate(45deg);
            flex: 0 0 auto;
        
            transition: transform 300ms;
        }

       
    }
   
    &__panel {
        padding: 0;
        //background-color: white;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        max-height: 0; 
    }

    &__content {
        padding: 4px 19px 22px 19px;
        //margin-bottom: 22px;
    }
}