.footer {
    background-color: $color-footer;
    width: 100%;
    padding: 1rem 0;
    min-height: $footer-height;
    
    .container {
        display: flex;
        justify-content: space-between;
        padding: 0;
    }
   

    a, span {
        color: $color-text-white;
        margin-right: 1rem;
        font-size: .7rem;
        text-decoration: none;

        &:last-child {
            margin-right: 0;
        }
    }


    @mixin mobileFooter() {
            flex-direction: column;

            .container { 
                display: flex;
                flex-direction: column;
            }
    
            .footer__linklist {
                display: flex;
                flex-direction: column;
                margin-bottom: .5rem;
            }
    
            .footer__info {
                line-height: 1.1;
            }
    
            a, span {
                font-size: .8rem;
                padding: 10px 0;
            }
    }

    @media all and (max-width: map-get($breakpoints, lg)){
		@include mobileFooter;
	}

    &--mobile {
		max-width: 480px;
        @include mobileFooter;
    }

}