.article {

    background-color: $color-on-surface0;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
    //border-radius: .5rem;
    overflow: hidden;
    min-height: 210px;
    cursor: pointer;


    @include devices(mobile) {
        flex-direction: column;

    }

    &__image {
        width: 100%;
        overflow: hidden;
        position: relative;
        display: block;
        flex-basis: 50%;
        
        img {
            height: 100%;
            width: 100%;
            transition: scale .3s ease;
            -o-object-fit: cover;
               object-fit: cover;
            -o-object-position: center;
               object-position: center;
        }
    }

    &__overlay {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        background: linear-gradient(270deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 1;
        
    }

    &__desc {
        flex-basis: 65%;
        padding: 2rem 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include devices(mobile) { 
            padding: 1.5rem;
            text-wrap: balance;
        }
    }

    &__title {
        pointer-events: none;
        margin-bottom: .5rem; 
        font-size: 1rem;
        line-height: 1.5;
        font-weight: bold;
        color: $color-text-primary;
    }

    &__subtitle {
        margin-bottom: 0;
        color: $color-text-primary;
    }

    &__info {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        gap: 1rem
    }

    &__date {
        font-size: .8rem;
    }

    &__link {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }


    &:hover {
        outline: 1px solid $color-primary;
        
        img {
            scale: 1.1;
        }
    }


    //TOPARTIKEL bzw. BILD KOMPLETT IM HINTERGRUND
    &--full  {
        display: flex;
        align-items: stretch;
        position: relative;
        overflow: hidden;
        min-height: unset;


        .article {


            &__image {
                flex-basis: 100%;
            }
            
            &__overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(48,48,48,.9) 0%, rgba(48,48,48,.7) 30%, rgba(48,48,48,0) 50%);
                z-index: 1;
                
            }

            &__desc {
                position: absolute;
                bottom: 2rem;
                left: 2rem;
                color: $color-text-white;
                z-index: 12;
                padding: 0;
                width: calc(100% - 4rem);

                &--small {
                    
                    bottom: .5rem;
                    left: .5rem;
                    width: calc(100% - 1rem);

                    .article__info {
                        margin-bottom: .5rem;
                    }

                    .article__title {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        margin-bottom: 0;
                        font-size: 1rem;
                        line-height: 1.25;
                        font-weight: normal;
                        color: $color-text-white;
                    }
                }

                @include devices(mobile) {
                    position: relative;
                    color: $color-text-primary;
                    bottom: unset;
                    left: unset;
                    padding: 1.5rem;
                    width: 100%;
                }
            }

            &__title {
                font-size: 1.5rem;
                line-height: 1.25;
                color: $color-text-white;

                @media screen and (max-width: 768px) {
                    color: $color-text-primary;
                    font-size: 1rem;
                }
            }

            &__subtitle {
                color: $color-text-white;

                @media screen and (max-width: 768px) {
                    color: $color-text-primary;
                }
            }

            &__tag {

                &--topleft {
                    position: absolute;
                    top: 1rem;
                    left: 1rem;
                }
                
            }

        }
        
    }

    &--vertical {
        flex-direction: column;
    }

    &--rounded {
        border-radius: .5rem;
        overflow: hidden;
    }

    &--small {
        min-height: unset;
        height: 140px;

        .article__image {
            flex-basis: 40%;
        }

        .article__desc {
            padding: 2rem;
        }
    }

    &--grey {
        background-color: $color-on-surface25;
    }
    
}


.article-detail {
    h2 {
        font-size: 1.25rem;
        line-height: 1.3;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1.5rem;
    }

    // ul li:not(:last-child) {
    //     margin-bottom: unset;
    // }

    ul, ol {
        margin-bottom: 1.5rem;
    }

    h6 {
        font-size: .75rem;
        line-height: 1.33;  
        font-weight: 300;
    }

    img {
        max-width: unset !important;
    }
}
