$flex-columns: 12;


.flex, .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;

    &--col, .col {
        flex-direction: column;
    }

    > * {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @for $i from 1 through $flex-columns {
        .col-#{$i} {
            position: relative;
            display: block;
            flex: 0 0 #{$i * 8.33333%};
            width: 100%;
            max-width: #{$i * 8.33333%};
        }
    }
    
    &--gap1 {
        gap: 1rem;
    }
    
    &--gap2 {
        gap: 2rem;
    }

    &--gap3 {
        gap: 3rem;
    }

    // > * {
    //     flex: 1 1 1;
    // }

    &--space-between {
        justify-content: space-between;
    }

    &--align-center {
        align-items: center
    }
}