//SKODANext
$skoda-library-font-name: "SKODANext" !default;
$skoda-library-font-dir: "./../font" !default;


// @font-face{
//   font-family:"SKODANext";
//   src: url("#{$skoda-library-font-dir}/SKODANext/SKODANext-Thin.woff2") format("woff2"),url("#{$skoda-library-font-dir}/SKODANext/SKODANext-Thin.woff") format("woff");
//   font-weight: 200;
//   font-style: normal;
// }

// @font-face{
//   font-family:"SKODANext";
//   src:url("#{$skoda-library-font-dir}/SKODANext/SKODANext-ThinItalic.woff2") format("woff2"),url("#{$skoda-library-font-dir}/SKODANext/SKODANext-ThinItalic.woff") format("woff");
//   font-weight: 200;
//   font-style: italic;
// }

// @font-face{
//   font-family:"SKODANext";
//   src:url("#{$skoda-library-font-dir}/SKODANext/SKODANext-LightItalic.woff2") format("woff2"),url("#{$skoda-library-font-dir}/SKODANext/SKODANext-LightItalic.woff") format("woff");
//   font-weight: 300;
//   font-style: italic;
// }

@font-face{
  font-family:"SKODANext";
  src:url("#{$skoda-library-font-dir}/SKODANext/SKODANext-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

// @font-face{
//   font-family:"SKODANext";
//     src:url("#{$skoda-library-font-dir}/SKODANext/SKODANext-Italic.woff2") format("woff2"),url("#{$skoda-library-font-dir}/SKODANext/SKODANext-Italic.woff") format("woff");
//     font-weight: 400;
//     font-style: italic;
// }

@font-face{
  font-family:"SKODANext";
  src:url("#{$skoda-library-font-dir}/SKODANext/SKODANext-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face{
font-family:"SKODANext";
  src:url("#{$skoda-library-font-dir}/SKODANext/SKODANext-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

// @font-face{
//   font-family:"SKODANext";
//   src:url("#{$skoda-library-font-dir}/SKODANext/SKODANext-BoldItalic.woff2") format("woff2"),url("#{$skoda-library-font-dir}/SKODANext/SKODANext-BoldItalic.woff") format("woff");
//   font-weight: 700;
//   font-style: italic;
// }

// @font-face{
//   font-family:"SKODANext";
//   src:url("#{$skoda-library-font-dir}/SKODANext/SKODANext-BlackItalic.woff2") format("woff2"),url("#{$skoda-library-font-dir}/SKODANext/SKODANext-BlackItalic.woff") format("woff");
//   font-weight: 900;
//   font-style: italic;
// }

@font-face{
    font-family:"SKODANext";
    src:url("#{$skoda-library-font-dir}/SKODANext/SKODANext-Black.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
}

// FALLBACKSCHRIFTEN RAUSSUCHEN !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
$font-family-skoda-next: 'SKODANext','Tahoma','Helvetica',sans-serif;