// Variables

// Typo
$font-size: 16px !default;
$font-size-small: $font-size * 0.75 !default;

$line-height: 1.5;

$font-sizes: (
    sm: .75rem,
    md: 1rem,
    lg: 1.5rem,
    xl: 2rem,
    xxl: 2.5rem
);

$font-size-sm: 12px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-xl: 30px;
$font-size-xxl: 40px;

$font-size-body: $font-size-md;
$font-size-body-sm: $font-size-lg;

$font-size-footer: $font-size-sm;

$font-size-nav: $font-size-md;
$font-size-btn: $font-size-md;

$font-size-icon: 32px;


// Gutters ???
$padding: 75px 75px;
$margin: 20px;


$nav-height: 60px !default;
$footer-height: 60px !default;


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1248px
);

// BREAKPOINTS
$breakpoints: (
  xs: 0,
  sm: 360px,
  md: 720px,
  lg: 1080px,
  xl: 1440px
);




$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 4,
  8: $spacer * 5,
  9: $spacer * 6
);


$grid-gutter-width: map-get($spacers, 5); 

//Gutter Sizes (for Gridgutters)
$gutter: 30px;
$gutter-sm: calc($gutter / 2); //15px
$gutter-lg: calc($gutter * 1.5); //45px
$gutter-xl: calc($gutter * 2); //60px
$gutter-xxl: calc($gutter * 3); //90px