.search {
    position: relative;

    &__btn {
        width: 100%;
        background-color: $color-on-surface0;
        border-radius: .5rem;
        border: none;
        display: flex;
        gap: .25rem;
        padding: 1rem 1.5rem;
        transition: .1s ease;


        &:before {
            font-family: $font-family-skoda-next-icon;
            font-weight: normal;
            content: '🔍';
            font-size: 1.25rem;
            align-self: center;
            justify-self: center;
            margin-top: -6px;
            color: $color-on-surface500;
        }

       
    }

    &__content {
        display: none;
        background-color: $color-on-surface0;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 50vh;
        width: 100%;
        border: .5rem;
        margin-top: 1rem;
        //box-shadow: 0 0 .5rem $color-transparent-primary;

        &.show {
            display: block;
        }

        ul {
            list-style-type: none;
            padding-left: 0 !important;
        }

        li, a {
            display: block;
            width: 100%;
            padding: .5rem 1.5rem;
            color: inherit;
            background-color: $color-on-surface0;
            list-style-type: none;
            cursor: pointer;

            &::before {
                content: '';
                padding-inline-start: 0;
            }

            &:hover {
                background-color: $color-on-surface50;
            }

            &:focus {
                outline: none;
                background-color: $color-on-surface50;
            }
        }
    }

    &__kat {
        color: $color-on-surface400;
        font-size: .9rem;
        padding: 1rem;
        padding-bottom: .5rem;
        border-bottom: 1px solid $color-on-surface25;
        margin-bottom: 0rem;
    }



    &__input {
        width: 100%;
        background-color: $color-on-surface0;
        border-radius: .5rem;
        display: flex;
        gap: .25rem;
        padding: 1rem 1.5rem;
        transition: .3s ease;

        &:before {
            font-family: $font-family-skoda-next-icon;
            font-weight: normal;
            content: '🔍';
            font-size: 1.25rem;
            align-self: center;
            justify-self: center;
            margin-top: -6px;
            color: $color-on-surface500;
        }


        input {
            background: transparent;
            border: none;
            font-size: 1rem;
            font-weight: 100;
            width: 100%;
            height: 100%;
            display: block;
            font-weight: bold;
            font-size: 1.25rem;

            &:focus {
                outline: none
            }
        }

        &:hover, &:focus, &:focus-within {
            //background-color: darken($color-on-surface0, 2%);
            box-shadow: 0 0 .5rem $color-transparent-primary;
        }
    
    }
}