@import '~@lvm/skoda-library/assets/scss/mixins/__mixins';

$skoda-library-font-dir: "../font";
$skoda-library-font-icons-dir: "../font";
@import '~@lvm/skoda-library/assets/scss/abstracts/__abstracts';

@import '~@lvm/skoda-library/assets/scss/base/_general';
@import '~@lvm/skoda-library/assets/scss/base/_typography';
@import '~@lvm/skoda-library/assets/scss/base/_utilities';

@import '~@lvm/skoda-library/assets/scss/components/_buttons';
@import '~@lvm/skoda-library/assets/scss/components/_card';
@import '~@lvm/skoda-library/assets/scss/components/_form';
@import '~@lvm/skoda-library/assets/scss/components/_scrollbar';
@import '~@lvm/skoda-library/assets/scss/components/_article';
@import '~@lvm/skoda-library/assets/scss/components/_label';
@import '~@lvm/skoda-library/assets/scss/components/_tile';
@import '~@lvm/skoda-library/assets/scss/components/_snackbar';
@import '~@lvm/skoda-library/assets/scss/components/_table';
@import '~@lvm/skoda-library/assets/scss/components/_termin';
@import '~@lvm/skoda-library/assets/scss/components/_download';
@import '~@lvm/skoda-library/assets/scss/components/_stoerer';
@import '~@lvm/skoda-library/assets/scss/components/_tabs';
@import '~@lvm/skoda-library/assets/scss/components/_checkbox';
@import '~@lvm/skoda-library/assets/scss/components/_search';
@import '~@lvm/skoda-library/assets/scss/components/_tooltip';
@import '~@lvm/skoda-library/assets/scss/components/_quicklink';
@import '~@lvm/skoda-library/assets/scss/components/_breadcrumb';

@import '~@lvm/skoda-library/assets/scss/layouts/_container';
@import '~@lvm/skoda-library/assets/scss/layouts/_accordion';
//@import '~@lvm/skoda-library/assets/scss/layouts/_navbar';
@import '~@lvm/skoda-library/assets/scss/layouts/_footer';
@import '~@lvm/skoda-library/assets/scss/layouts/_dialog';
@import '~@lvm/skoda-library/assets/scss/layouts/_vorwort';
@import '~@lvm/skoda-library/assets/scss/layouts/_login';
@import '~@lvm/skoda-library/assets/scss/layouts/_grid';
@import '~@lvm/skoda-library/assets/scss/layouts/_flex';
@import '~@lvm/skoda-library/assets/scss/layouts/_container';
@import '~@lvm/skoda-library/assets/scss/layouts/_trend';
@import '~@lvm/skoda-library/assets/scss/layouts/_road';
@import '~@lvm/skoda-library/assets/scss/layouts/_produkte';
@import '~@lvm/skoda-library/assets/scss/layouts/_banner';
@import '~@lvm/skoda-library/assets/scss/layouts/_learning';
@import '~@lvm/skoda-library/assets/scss/layouts/_video';

//@import 'swiper';

@import 'slickslider_fix';

@import 'nav_sidebar';
@import 'phoneframe';
@import 'news';
@import 'sidebar';
@import 'kompakt__dashboard';
@import 'switch';
@import 'container';
@import 'pagination';
@import 'article';
@import 'swiper';
@import 'kompakt';
//@import 'countdown';

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";
@import 'glide';
