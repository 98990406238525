// Typography

h1,
h2,
h3,
h4,
h5,
h6 {
  //color: $color-text-primary;
  //@include heading-font;
}

.display {
  font-size: 72px;
  line-height: 88px;
  font-weight: 700;

  @include devices(desktop) {
    font-size: 56px;
    line-height: 68px;
  }

  @include devices(mobile) {
    font-size: 40px;
    line-height: 48px;
  }
}

h1, .h1 {
  font-size: 44px;
  line-height: 52px;
  font-weight: 700;

  @include devices(desktop) {
    font-size: 38px;
    line-height: 48px;
  }

  @include devices(mobile) {
    font-size: 33px;
    line-height: 40px;
  }
}

h2, .h2 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;

  @include devices(desktop) {
    font-size: 32px;
    line-height: 38px;
  }

  @include devices(mobile) {
    font-size: 28px;
    line-height: 36px;
  }
}

h1.light, .h1-light, h2.light, .h2-light {
  font-weight: 300;
}

h3, .h3 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;

  @include devices(desktop) {
    font-size: 27px;
    line-height: 32px;
  }

  @include devices(mobile) {
    font-size: 23px;
    line-height: 28px;
  }
}

h4, .h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;

  @include devices(desktop) {
    font-size: 23px;
    line-height: 28px;
  }

  @include devices(mobile) {
    font-size: 20px;
    line-height: 24px;
  }
}

h5, .h5, h5.subheadline, .subheadline {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;

  @include devices(desktop) {
    font-size: 16px;
    line-height: 24px;
  }

  @include devices(mobile) {
    font-size: 16px;
    line-height: 24px;
  }
}

h6, .h6 {
  font-size: 18px;
  font-weight: 700;
}

p, body, .typo--body {
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  letter-spacing: 0.002em;

  @include devices(desktop) {
    font-size: 16px;
    line-height: 24px;
  }

  @include devices(mobile) {
    font-size: 16px;
    line-height: 24px;
  }
}

.disclaimer {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: 0.002em;

  @include devices(desktop) {
    font-size: 12px;
    line-height: 16px;
  }

  @include devices(mobile) {
    font-size: 12px;
    line-height: 16px;
  }
}

.caption-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;

  @include devices(desktop) {
    font-size: 14px;
    line-height: 20px;
  }

  @include devices(mobile) {
    font-size: 14px;
    line-height: 20px;
  }
}

.caption-2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;

  @include devices(desktop) {
    font-size: 12px;
    line-height: 16px;
  }

  @include devices(mobile) {
    font-size: 12px;
    line-height: 16px;
  }
}





p, ul {
  margin-bottom: 1rem;
}

p {
  line-height: 1.5;
}

ul {
  padding: 0;
  list-style: none;
  -webkit-padding-start: 1rem;
          padding-inline-start: 1rem;

  li {
    padding-left: 0;
    line-height: 1.5;
    
    &::before {
      content: "󿿬";
      font-family: $font-family-skoda-next-icon;
      display: inline-block;
      margin-left: calc( 1rem*-1 );
      width: 1rem;
      font-size: .75em;
      line-height: 1em;
    }

    // &:not(:last-child) {
    //   margin-bottom: .5rem;
    // }
  }

}

sup {
  line-height: 1;
}

ol {
  padding: 0;
  -webkit-padding-start: 1.5rem;
  padding-inline-start: 1.5rem;

  li {
    padding-left: 0;
    line-height: 1.5;
  }
}


a {
  text-decoration: none;
  color: $color-primary;
  cursor: pointer;;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

strong {
  font-weight: normal;
}

small {
  font-size: .75rem;
  line-height: 1;
}


.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-highlight {
  color: $color-secondary;

  &--dark {
    color: $color-tertiary;
  }

  &--alert, &--error {
    color: $color-semantic-alert;
  }

  &--primary {
    color: $color-primary;
  }

  &--grey {
    color: $color-on-surface200;
  }
}

.font-weight-100, .font-weight-200, .font-weight-300, .font-weight-light {
  font-weight: 300;
}

.font-weight-400, .font-weight-500, .font-weight-600, .font-weight-normal, .font-weight-regular {
  font-weight: normal;
}

.font-weight-700, .font-weight-800, .font-weight-900, .font-weight-bold {
  font-weight: bold;
}


blockquote {
  quotes: "„" "“";
  padding-left: 1em;
  position: relative;
  font-family: $font-family-skoda-next;

  &:before {
    content: open-quote;
    position: absolute;
    left: -1.5rem;
    top: -3.5rem;
    font-size: 8em;
    line-height: 0.1em;
    //vertical-align: -0.4em;
  }

  p {
    display: inline;
  }

  &.blockquote--highlight {
    color: $color-secondary;
    
    &:before {
      margin-right: .25rem;
      font-style: italic;
    }
  }
}