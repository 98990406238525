

.grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(12, 1fr);
    width: 100%;

    &__col {

        &-1 { 
            grid-area: auto /auto / auto / span 1;
        }

        &-2 { 
            grid-area: auto /auto / auto / span 2;
        }

        &-3 { 
            grid-area: auto /auto / auto / span 3;
        }

        &-4 { 
            grid-area: auto /auto / auto / span 4;
        }

        &-5 { 
            grid-area: auto /auto / auto / span 5;
        }

        &-6 { 
            grid-area: auto /auto / auto / span 6;
        }

        &-7 { 
            grid-area: auto /auto / auto / span 7;
        }

        &-8 { 
            grid-area: auto /auto / auto / span 8;
        }

        &-9 { 
            grid-area: auto /auto / auto / span 9;
        }

        &-10 { 
            grid-area: auto /auto / auto / span 10;
        }

        &-11 { 
            grid-area: auto /auto / auto / span 11;
        }

        &-12 { 
            grid-area: auto /auto / auto / span 12;
        }

    }

    @media all and (max-width: 992px){
        &__col-6, &__col-7, &__col-8, &__col-9, &__col-10, &__col-11 {
            grid-area: auto /auto / auto / span 12;
        }

        &__col-1, &__col-2, &__col-3, &__col-4, &__col-5 {
            grid-area: auto /auto / auto / span 6;
        }
        
    }

    @media all and (max-width: 768px){ 
        &__col-1, &__col-2, &__col-3, &__col-4, &__col-5 {
            grid-area: auto /auto / auto / span 12;
        }
    }


    @include devices(tablet) {
        &__col-md{

            &-1 { 
                grid-area: auto /auto / auto / span 1;
            }
    
            &-2 { 
                grid-area: auto /auto / auto / span 2;
            }
    
            &-3 { 
                grid-area: auto /auto / auto / span 3;
            }
    
            &-4 { 
                grid-area: auto /auto / auto / span 4;
            }
    
            &-5 { 
                grid-area: auto /auto / auto / span 5;
            }
    
            &-6 { 
                grid-area: auto /auto / auto / span 6;
            }
    
            &-7 { 
                grid-area: auto /auto / auto / span 7;
            }
    
            &-8 { 
                grid-area: auto /auto / auto / span 8;
            }
    
            &-9 { 
                grid-area: auto /auto / auto / span 9;
            }
    
            &-10 { 
                grid-area: auto /auto / auto / span 10;
            }
    
            &-11 { 
                grid-area: auto /auto / auto / span 11;
            }
    
            &-12 { 
                grid-area: auto /auto / auto / span 12;
            }

        }
    }


    &--gap {

        &0 {
            gap: 0;
        }

        &1 {
            gap: 1rem;
        }

        &2 {
            gap: 2rem;
        }

        &3 {
            gap: 3rem;
        }
        
    }

    &--rowgap0 {
        row-gap: 0;
    }


    @include devices(mobile) {
        gap: 1rem;

        &--gap {
            &3, &2 {
                gap: 1rem;
            }
           
        }
    }

    &__col-sm-12 {
        @include devices(mobile) {
            grid-area: auto /auto / auto / span 12;
        }
    }

    &__offset {
        &-1 {
            grid-column-start: 2;
        }

        &-2 {
            grid-column-start: 3;
        }

        &-3 {
            grid-column-start: 4;
        }

        &-4 {
            grid-column-start: 5;
        }

        &-5 {
            grid-column-start: 6;
        }

        &-6 {
            grid-column-start: 7;
        }

        &-7 {
            grid-column-start: 8;
        }

        &-8 {
            grid-column-start: 9;
        }

        &-9 {
            grid-column-start: 10;
        }

        &-10 {
            grid-column-start: 11;
        }

        &-11 {
            grid-column-start: 12;
        }
    }


    &__offset-1, &__offset-2, &__offset-3, &__offset-4, &__offset-5, &__offset-6, &__offset-7, &__offset-8, &__offset-9, &__offset-10, &__offset-11 {
        @include devices(mobile) {
            grid-column-start: 1;
        }
    }

    @include devices(tablet) {
      &__offset-md {
        &-0 {
          grid-column-start: 1;
        }
        &-1 {
          grid-column-start: 2;
        }

        &-2 {
          grid-column-start: 3;
        }

        &-3 {
          grid-column-start: 4;
        }

        &-4 {
          grid-column-start: 5;
        }

        &-5 {
          grid-column-start: 6;
        }

        &-6 {
          grid-column-start: 7;
        }
      }
    }

    &__offset-md-1, &__offset-md-2, &__offset-md-3, &__offset-md-4, &__offset-md-5, &__offset-md-6 {
      @include devices(mobile) {
        grid-column-start: 1;
      }
    }

    &__col-md-12 {
        @include devices(mobile) {
            grid-area: auto /auto / auto / span 12;
        }
    }


    &__order-md-1 {
        @include devices(mobile) {
            order: 1;
        }
    }

    &__order-md-2 {
        @include devices(mobile) {
            order: 2;
        }
    }
    

    &--rowspan2 {
        grid-row: span 2;
    }


    &__align-center {
        align-items: center;
    }

    &__justify-center {
        justify-content: center;
    }

    &__in_the_middle {
        justify-content: center;
        align-items: center;
        height: 100%;
       
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

}




.grid {

    &--topartikel {

        .article {

            grid-area: auto / auto / auto / span 4;

            &:first-child {
                grid-area: auto / auto / span 2 / span 8;
            }
        }

    }
}