.table {
    //font-size: calc(1rem - 2px);
    border-spacing: 0;
    border-radius: .5rem;
    border: 1px solid $color-on-surface50;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    //display: block;
    

    thead {
        //background-color: $color-on-surface50;
        
        tr {
            &:first-child {
                td {
                    text-align: center; 
                    padding: .5rem .5rem;  
                }
            }

            &:last-child {
                td {
                    font-size: .8rem; 
                    border-bottom-color: $color-on-surface400;
                    line-height: 1.3;
                }
            }
        }
       
    }

    tbody tr:last-child td {
        border-bottom: none;
        
    }

    td {
        padding: .5rem;
        border-bottom: 1px solid $color-on-surface50;

        &:first-child {
            text-align: left;
        }
    }

    &--marken {
        width: 100%;

        thead {
            //background-color: $color-on-surface50;
            
            tr {
                &:first-child {
                    td {
                        text-align: center; 
                        padding: .5rem .5rem;  
                    }
                }

                &:last-child {
                    td {
                        font-size: .8rem; 
                        border-bottom-color: $color-on-surface400;
                    }
                }
            }
           
        }

        tbody tr:last-child td {
            border-bottom: none;
            
        }

        td {
            padding: .5rem;
            border-bottom: 1px solid $color-on-surface50;

            &:first-child {
                text-align: left;
            }
        }



    }
   
    &--highlight, tr:hover {
        background-color: $color-on-surface50;
    }

    &--center {
        text-align: center;
    }

    &--border-right {
        border-right: 1px solid $color-on-surface50;
    }

    &__footer {
        text-align: center;
    }

    &--bg {
        background-color: $color-background-primary;
    }
}