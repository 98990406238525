
.snackbar {
  visibility: hidden; 
  min-width: 150px;
  max-width: 500px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: $color-tertiary; 
  color: $color-on-surface0; 
  text-align: center; 
  border-radius: 4px; 
  padding: 16px; 
  position: fixed; 
  z-index: 10; 
  right: 2rem; 
  top: 2rem;
  box-shadow: 0 0 .5rem $color-transparent-secondary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  word-wrap: balance;

  &.show {
    visibility: visible;
    -webkit-animation: fadein 0.6s;
    animation: fadein 0.3s;
  }

  &__close{
    cursor: pointer;
  }

  @-webkit-keyframes fadein {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @-webkit-keyframes fadeout {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.5);
    }
  }
  
  @keyframes fadeout {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  &--styleguide {
    display: inline-block;
    visibility: visible; 
    position: relative; 
    right: unset; 
    top: unset;
  }

  &--bottom {
    top: unset;
    bottom: 2rem;
  }


  &--alert {
    background-color: $color-semantic-alert; 
    color: $color-text-white; 
  }


  &--oldci {
    top: 80px;
    text-align: left;
    max-width: unset;
    display: flex;
    visibility: visible;
    font-weight: 300;
    position: fixed;
    background-color: $color-secondary;
    color: $color-tertiary;
    z-index: 90;
    transform-origin: right;

    &.snackbar--show {
      -webkit-animation: fadein 0.3s forwards;
              animation: fadein 0.3s forwards;
    }

    &.snackbar--hide {
      -webkit-animation: fadeout 0.3s forwards;
              animation: fadeout 0.3s forwards;
      pointer-events: none;
    }

    &.snackbar--hidden {
      opacity: 0;
      transform: scale(0.5);
      pointer-events: none;
    }

    .snackbar__content {
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }

    .sa-i {
      font-size: 2rem;
      margin-top: -8px;
    }

    .ballotx {
     
      cursor: pointer;
    }


    @media all and (max-width: map-get($breakpoints, lg)){ 
      right: 0;
      top: 60px;
    }
  }

}



  