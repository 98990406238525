* {
    margin: 0;
    box-sizing: border-box;
    scroll-padding-top: 2 * $nav-height;
}

html, body {
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    display: block;

}


body {
    background-color: $color-surface-primary;
    position: relative;

    margin: 0;
    
    //padding-top: $nav-height;

    font-family: $font-family-skoda-next;
    font-size: $font-size;
    font-weight: 300;
    color: $color-text-primary;

    @include devices(tablet) {
        //font-size: $font-size-body-sm;
    }
}

img {
    display: block;
}

.content {
    padding-top: $nav-height * 2;
    padding-bottom: $nav-height;
    min-height: calc(100% - $footer-height);
 
    &--centered {
     padding-top: $nav-height * 2;
     display: flex;
     justify-content: center;
     align-items: center;
     min-height: calc(100% - $footer-height);
 
    }
 }




// Selection Colors
::-moz-selection {
    background: $color-primary;
    color: $color-text-white;
}
::selection {
    background: $color-primary;
    color: $color-text-white;
}



.icon {
    max-width: 40px;

    svg {
        width: 40px;
        margin-right: 10px;

        circle, polyline, polygon, line {
            stroke: $color-grey;
        }
        
        path {
            fill: $color-grey;
        }
    }
}

hr {
    border: 1px solid $color-on-surface25;
}