.download {

    display: flex;
    align-items: center; 
    gap: .5rem;
    color: $color-primary;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &__text {
        text-decoration: underline;
    }

}