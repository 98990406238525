.termin {
    display: flex;
    background-color: $color-background-primary;
    max-height: 200px;
    cursor: pointer;
    transition: scale .1s ease;
    color: $color-text-primary;

    &--dark {
        background-color: $color-on-surface25;
    }

    &__image {
        flex-basis: 50%;
        aspect-ratio: 16 / 9;

        img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
               object-fit: cover;
        }
    }

    &__content {
        flex-basis: 50%;
        padding: 2rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;

       
        p:nth-child(2), .termin__teaser {
            text-overflow:ellipsis;
            overflow: hidden;
            display: -webkit-box !important; 
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal; 
            margin-bottom: 0;
        }

        .label {
            margin-bottom: .5rem;
        }

    }

    &__icon {
        position: absolute;
        font-size: 1.5rem;
        top: 1rem;
        right: 1rem;
    }


    &:hover {
        outline: 1px solid $color-primary;
    }

    &:active {
        scale: .99;
    }
    
    &--disabled {
        pointer-events: none;
        opacity: .5;
    }



    &--detail {
        padding: 2rem;
        padding-right: 6rem;
        position: relative;
        display: block;
        max-height: unset;
        //height: 100%;

        // &:hover{
        //     outline: none;
        //     cursor: inherit;
        // }

        // &:active {
        //     scale: unset;
        // }

        table {
            font-size: calc(1rem - 2px);
        }

        .termin {

            &__table {
                font-size: calc(1rem - 2px);
                
                thead {
                    text-align: left;
                }

                tbody {
                    display: grid;
                    grid-auto-rows: 1fr;
                }

                tr {
                    display: grid;
                    grid-template-columns: auto auto 1fr auto;
                    align-items: center;
                    gap: 1rem;
                    
                    td {
                        text-align: left;
                        text-wrap: balance;
                        padding: .25rem .5rem;
                        
                        &:last-child {
                            white-space: nowrap;
                            text-align: right;
                        }
                    }

                    // &:nth-child(odd){
                    //     background-color: $color-on-surface0;
                    // }
                    
                    &:nth-child(even){
                        background-color: $color-on-surface25;
                    }

                    
                }
            }

            &__plaetze {
                display: block;
                position: absolute;
                //transform: translateY(-50%);
                bottom: 1.5rem;
                right: 2rem;
                text-align: center;

                span:first-child {
                    font-size: 2rem;
                }
            }

            &__praesenzort {
                line-height: 1;
                
            }

            &__footer {
                margin-top: 1rem;
                display: flex;
                justify-content: space-between;
            }

            &__ics {
                display: inline-block;
                margin-left: auto;
                text-align: right;
                color: $color-primary;
            }
        }
    }


  
}



.gav-termin {
    background-color: $color-on-surface25;
    
    &__button {
        font-family: $font-family-skoda-next;
        cursor: pointer;
        border: none;
        width: 100%;
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;

        div:last-child{
            margin-left: auto;
        }
    }

    &__icons {
        margin-right: .5rem;
        flex-basis: 5%;
        display: flex;
        gap: .5rem;
        font-size: 1.25rem;

        .sa-i {
            margin-bottom: 6px;
        }
    }

    &__content {
        padding: 2rem;
        padding-top: 1rem;
    }

    &:hover {
        outline: 1px solid $color-primary;
    }
}