html:has(dialog[open]:modal) {
  overflow: hidden;
}

dialog, .dialog {

    border-radius: .5rem;
    border: none;
    box-shadow: 0 0 1rem $color-transparent-primary;
    margin: auto;
    //position: fixed;
    padding: 0;
    max-width: 39rem;
    max-height: 90vh;
    opacity: 0;
    transition: opacity .5s;
    display: none;
    flex-direction: column;

    &--sm, &--small {
      max-width: 25.5rem;
    }

    &--lg {
      max-width: 50vw;
    }

    &--xl, &--wide {
      max-width: 60vw;
      width: 100%;
    }

    &--full {
      max-width: 95vw;
      width: 100%;
    }

    @include devices(tablet) {
      max-width: 100%;
      max-height: 100%;

      &--wide, &--small {
        max-width: 100%;
        max-height: 100%;
      }


    }

    &::-webkit-backdrop {
        -webkit-backdrop-filter: blur(.25rem);
        backdrop-filter: blur(.25rem);
    }

    &::backdrop {
        background-color: $color-transparent-primary;
        -webkit-backdrop-filter: blur(.25rem);
        backdrop-filter: blur(.25rem);
    }
    
    &__close {
        cursor: pointer;
        border: none;
        background: none;
        position: absolute;
        top: .5rem;
        right: .5rem;
        font-size: 1rem;
    }

    &__content {
        margin: 1rem;
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
        padding: 2.5rem;
        padding-top: 0;
        padding-bottom: 2rem;
        overflow-y: auto;

       
        // background:
        //   /* Shadow covers */
        //   linear-gradient(white 30%, rgba(255,255,255,0)),
        //   linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
          
        //   /* Shadows */
        //   radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),
        //   radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
        // background:
        //   /* Shadow covers */
        //   linear-gradient(white 30%, rgba(255,255,255,0)),
        //   linear-gradient(rgba(255,255,255,0), white 100%) 0 100%,
          
        //   /* Shadows */
        //   linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.1)) 0 100%;
        // background-repeat: no-repeat;
        // background-color: white;
        // background-size: 100% 3rem, 100% 3rem, 100% 3rem, 100% 3rem;

        // /* Opera doesn't support this in the shorthand */
        // background-attachment: local, local, scroll, scroll;

    }

    &__headline {
        margin-bottom: 1.5rem;
    }

    &__footer {
        padding: 2.5rem 3.5rem;
        

        &--center {
            display: flex;
            justify-content: center;
            align-items: center;
        }
 

    }

    &[open] {
      display: flex;
        -webkit-animation: Fade .5s ease forwards;
                animation: Fade .5s ease forwards;
    }

    @-webkit-keyframes Fade{
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

    @keyframes Fade{
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }


    ::-webkit-scrollbar {
      width: .25rem;
      height: .25rem;
      cursor: pointer;
    }

    ::-webkit-scrollbar-track {
      margin-bottom: 2rem;
    }

    &:focus,&:focus-visible,&:focus-within {
      outline: none!important;
    }
}


//Dialog als absolute
.dialog {

  &__popup {
    position: absolute;
    z-index: 10;

    .dialog {

      &__content {
        padding: 1.5rem;
        padding-top: 0;
      }
    }
  }
}