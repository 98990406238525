.produkte {

    &__liste {
        display: flex;
        gap: 1rem;
    }

    &__item {
        opacity: .5;
        cursor: pointer;
        min-width: unset;
        min-height: unset;
        padding: 1rem;
        aspect-ratio: unset;

        &:hover {
            opacity: 1;
        }

        &--selected {
            opacity: 1;
            outline: 1px solid $color-primary;
        }
    }

    &__wettbewerber {
        display: flex;
        gap: 1rem;

        > div {
            flex: 1 1 25%;
        }
    }

    &__wettbewerber-item {
        position: relative;
        cursor: pointer;

        img {
            margin-bottom: .5rem;
            // height: 100%;
            width: 100%;
            // -o-object-fit: cover;
            //    object-fit: cover;
        }

        &:hover {
            .produkte__wettbewerber-hover {
                opacity: 1;
            }
        }
    }

    &__wettbewerber-hover {
        font-size: calc(1rem - 2px);
        white-space: nowrap;
        z-index: 10;
        opacity: 0;
        transition: opacity .3s ease;
        position: absolute;
        top: 100%;
        background-color: $color-on-surface0;
        box-shadow: 0 0 .5rem $color-transparent-secondary;
        padding: 1rem;
    }
}