.login {

    width: 100%;
    max-width: 30rem;

    &__header {
        margin-bottom: 2rem;
    }

    &__form {
        margin-bottom: 2rem;
    }

}


.facette {
    position: absolute;
    z-index: 25;
    width: 120px;
    bottom: 60px;
    left: 2rem;
    top: unset;
    right: unset;

    &:after {
        content: "";
        position: absolute;
        left: -2rem;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 600px 0 0 200px;
        border-color: transparent transparent transparent $color-emerald-green ;
        z-index: -1;
        
        @media screen and (max-width: 768px) { 
            display: none;
        }
        
    }

    @media screen and (max-width: 768px) { 
        position: relative;
        background-color: $color-emerald-green;
        top: 0;
        left: 0;
        height: $nav-height;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: $nav-height;
    }

   

    &--2 { 
        left: unset;
        bottom: unset;
        right: 2rem;
        top: 60px;

            &:after{
                left: unset;
                top: 0;
                bottom: unset;
                right: -2rem;
                border-width: 0 400px 100px 0;
                border-color:  transparent $color-emerald-green transparent transparent;
            }

            @media screen and (max-width: 768px) { 
                display: none;
            }
        
    }

   
}
