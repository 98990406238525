.tabs {  
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    padding-left: 0 !important;

    button {
        //flex-basis: 100%;
        white-space: nowrap;
        flex-grow: 0;
        text-align: center;
        list-style-type: none;
        color: $color-on-surface100;
        font-size: 1.25rem;
        font-weight: bold;
        cursor: pointer;
        background: none;
        border: none;
        transition: .3s ease;
        border-bottom: 2px solid transparent;

        &::before {
            content: none;
        }

        &.active, &:hover {
            color: $color-text-primary;

            border-bottom: 2px solid $color-primary;
        }
    }


    &__content{
        display: none
    }

    
}


.triangle_svg {


    .green {
        fill: $color-primary;
    }
}

// .green {
//     color: $color-white;
// }