$control-size: 4rem;

.video {
    position: relative;

    &:hover {
        
        .video__controls {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__controls {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-text-white;
        z-index: 10;
        opacity: 0;
        pointer-events: none;
        transition: .5s ease;

        .btn__center {
            background-color: transparent;
            border-radius: 10rem;
            border: none;
            color: inherit;
            cursor: pointer;
            transition: .3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: $control-size;
            height: $control-size;
            font-size: calc($control-size / 1.5);

            &::before {
                margin-top: -.5rem;
            }

            &:hover {
                background-color: rgba($color-on-surface600 , .75);
            }
        }
    }
}