.checkbox {
    position: relative;
    
    input {
        opacity: 0;
        position: absolute;
        bottom: 0;
        width: 1px;
        left: 10px;

        // &:checked ~ label {
        //     background-color: $color-primary;
        //     border-color: $color-primary;

        //     &:after {
        //         display: block;
        //     }

        //     &:hover {
        //         border-color: $color-primary;
        //     }
        // }
    }
      
    label {
        display: inline-block;
        position: relative;
        padding-left: 1.5rem;
        cursor: pointer;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 1rem;
            height: 1rem;
            left: 0;
            top: 4px;
            border: 1px solid $color-on-surface700;
            border-radius: 4px;
        }

        &:after {
            content: "✓";
            font-size: 17px;
            font-family: $font-family-skoda-next-icon;
            color: white;
            position: absolute;
            left: 0px;
            top: -3px;
            height: 1rem;
            width: 1rem;
            display: none;

            // width: 40%;
            // height: 60%;
            // border: solid white;
            // transform: rotate(45deg) translate(-50%, -50%);
        }
    }

    :checked + label {

        &::before {
            border: 1px solid $color-primary;
            background-color: $color-primary;
        }
        &:after {
            display: block;
        }
    }
      
    &:hover label {
        border-color: $color-on-surface800;
    }

    :disabled + label {
        color: $color-on-surface400;
        &::before {
            border-color: $color-on-surface400;
        }
    }
}
