

.btn {
	display: inline-block;
	padding: 14px 24px;
	border-radius: 10rem;
	font-weight: 700;
	font-size: 1rem;
	font-family: $font-family-skoda-next;
	line-height: 1rem;
	color: $color-text-primary;
	background-color: $color-secondary;
	border: none;
	cursor: pointer;
	transition: all 100ms ease;
	word-break: keep-all;
	text-align: center;

	transition: background-color 250ms;

	&:hover, &--hover {
		background-color: $color-hover-secondary;
		color: $color-tertiary;
	}

	&:focus, &--focus {
		outline: 2px solid $color-surface-quarternary;
		outline-offset: 2px;
	}

	&:active, &--active {
		transform: scale(0.95);
	}

	.sa-i {
		line-height: 1;
	}


	// PRIMARY
	&__primary {
		
		&:disabled, &--disabled, [disabled] {
			pointer-events: none;
			color: $color-disabled-tertiary;
			background-color: $color-disabled-secondary;
		}
	}
	
	//SECONDARY
	&__secondary { 
		background: $color-surface-quarternary;
		color: $color-text-white;

		&:hover, &--hover {
			background-color: $color-on-surface600;
			color: $color-text-white;
		}

		&:disabled, &--disabled {
			pointer-events: none;
			color: $color-disabled-tertiary;
			background-color: $color-disabled-secondary;
		}

		&:focus, &--focus {
			outline: 2px solid $color-surface-quarternary;
			outline-offset: 2px;
		}

	}
	
	//TERTIARY
	&__tertiary { 
		background: transparent;
		color: $color-text-primary;

		&:hover, &--hover {
			background-color: $color-transparent-primary;
		}

		&:disabled, &--disabled {
			pointer-events: none;
			color: $color-disabled-secondary;
		}

		&:focus, &--focus {
			outline: 2px solid $color-surface-quarternary;
		}

		&--white {
			color: $color-text-white;

			&:hover, &.btn__tertiary--hover {
				background-color: $color-transparent-primary;
			}
	
			&:disabled, &.btn__tertiary--disabled {
				pointer-events: none;
				color: $color-disabled-secondary;
			}
	
			&:focus, &.btn__tertiary--focus {
				outline: 2px solid $color-text-white;
			}
		}
		
	}
	
	//GHOST
	&__ghost { 
		background: transparent;
		color: $color-text-primary;
		border: 2px solid $color-text-primary;

		&:hover, &--hover {
			background-color: $color-transparent-primary;
			border: 2px solid $color-text-primary;
			color: $color-text-primary;

		}

		&:disabled, &--disabled {
			pointer-events: none;
			border: 2px solid $color-disabled-secondary;
			color: $color-disabled-secondary;
		}

		&:focus, &--focus {
			outline: 2px solid $color-text-primary;
			outline-offset: 2px;
		}

		&--white {
			color: $color-text-white;
			border: 2px solid $color-text-white;

			&:hover, &.btn__ghost--hover {
				background-color: $color-transparent-primary;
				border: 2px solid $color-text-white;
				color: $color-text-white;
			}
	
			&:disabled, &.btn__ghost--disabled {
				pointer-events: none;
				color: $color-disabled-secondary;
				border: 2px solid $color-disabled-secondary;
			}
	
			&:focus, &.btn__ghost--focus {
				outline: 2px solid $color-on-surface0;
				outline-offset: 2px;
			}
		}
		
	}

	//TEXTLINK(OLD && NAVIGATION
	&__textlink, &__navigation { 
		background: transparent;
		color: $color-text-primary;
		padding: 8px 0;
		border-radius: 2px;
		display: flex;
		align-items: center;

		&:hover, &--hover {
			background-color: transparent;
			transition: transform .3s ease;

			.btn__textlink--arrow {
				transform: translateX(.25rem);
			}
		}

		&:disabled, &--disabled {
			pointer-events: none;
			color: $color-disabled-secondary;
			
		}

		&:focus, &--focus {
			outline: 2px solid $color-surface-quarternary;
		}


		&--arrow {
			transition: transform .3s;
			margin-left: 4px;
			margin-top: -4px;
		}

		&--white {
			color: $color-text-white;

			&:hover {
				color: $color-text-white;
			}
		
		}
		
	}

	&__filter {
		background-color: $color-on-surface200;
		color: $color-text-primary;

		&:hover {
			background-color: $color-on-surface100;
			color: $color-text-primary;
		}
	}


	//COPACT SIZE
	&--compact {
		padding: 8px 16px;
	}

	//WITH ICONS
	&--icon {
		padding: 12px;
		width: 44px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;

		img, svg {
			width: 100%;
			height: 20px;
			padding: 3px;
		}
	}

	&--icontext {
		padding: 12px 24px;
		display: flex;
		justify-content: center;
		align-items: center;

		img, svg {
			width: 100%;
			height: 13px;
			margin-top: 1px;
			margin-right: 10px;
		}
	}

	&--center {
		display: block;
		margin: auto;
	}
	

	//PULSE ANIMATION
	&--pulse { //pulsierender Button (aus dem Konfigurator)
    -webkit-animation: pulse .8s infinite;
            animation: pulse .8s infinite;
	}
	
	@-webkit-keyframes pulse {
		0% {
    	box-shadow: 0 0 0 0px rgb(78 181 46 / 45%);
			}
		100% {
			box-shadow: 0 0 0 1.2rem rgb(78 181 46 / 0%);
			}
		}
	
	@keyframes pulse {
		0% {
    	box-shadow: 0 0 0 0px rgb(78 181 46 / 45%);
			}
		100% {
			box-shadow: 0 0 0 1.2rem rgb(78 181 46 / 0%);
			}
		}


	//TOTOP BUTTON
	&__totop {
		display: block;
		opacity: 0; 
		position: fixed; 
		bottom: 2rem; 
		right: 2rem; 
		z-index: 99; 
		border: none; 
		border-radius: 50%;
		outline: none; 
		background-color: $color-on-surface100;
		background-color: $color-transparent-primary; 
		color: white; 
		cursor: pointer; 
		padding: 15px; 
		width: 3rem;
		height: 3rem;
		transition-property:  opacity, background-color, color;
		transition-duration: .5s;
		transition-timing-function: ease;

		&:hover {
			background-color: $color-hover-primary; 
			color: $color-on-surface0;
		}

		&--styleguide {
			display: block;
			position: relative;
			bottom: unset;
			right: unset;
		}
	}
}



.arrow {
	border: solid $color-on-surface0;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 5px;
}

.right {
	transform: rotate(-45deg);
}

.left {
	transform: rotate(135deg);
}

.up {
	transform: rotate(-135deg) translate(-3px, -3px);

}

.down {
	transform: rotate(45deg);
}


//Button Group
.btn {
	&__group {
		display: flex;
		justify-content: flex-start;
		gap: .5rem;

		&--vertical {
			flex-direction: column;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
			margin-left: auto;
			margin-right: auto;
		}

		button, .btn {
			//flex-basis: 100px;
			flex-grow: 1;
		}

		@include devices(mobile) {
			flex-direction: column;
		}
	}
}