// NEW CI
$color-primary:         #419468FF !default;
$color-secondary:       #78faaeFF !default;
$color-tertiary:        #0e3a2fFF !default;
$color-quarternary:     #e8fff1FF !default;

$color-hover-primary:   #59bc87FF !default;
$color-hover-secondary: #a8ffccFF !default;

$color-on-surface900:   #161718FF !default;
$color-on-surface850:   #232425FF !default;
$color-on-surface800:   #303132FF !default;
$color-on-surface750:   #3C3D3EFF !default;
$color-on-surface700:   #464748FF !default;
$color-on-surface600:   #5a5b5cFF !default;
$color-on-surface500:   #7c7d7eFF !default;
$color-on-surface400:   #8E8F90FF !default;
$color-on-surface300:   #9e9fa0FF !default;
$color-on-surface200:   #c4c6c7FF !default;
$color-on-surface100:   #d8d8d8FF !default;
$color-on-surface50:    #E4E4E4FF !default;
$color-on-surface25:    #F1F1F1FF !default;
$color-on-surface0:     #ffffffFF !default;

$color-disabled-primary:   $color-on-surface200;
$color-disabled-secondary: $color-on-surface100;
$color-disabled-tertiary:  $color-on-surface300;

$color-text-primary: $color-on-surface900;
$color-text-white: $color-on-surface0;
$color-font: $color-text-primary;

$color-background-primary: $color-on-surface0;

$color-transparent-primary:   rgba($color-on-surface900, 0.06);
$color-transparent-secondary: #1a1b1c1F;
$color-transparent-tertiary:  rgba(#F3F3F3, 0.12);
$color-transparent-backdrop:  rgba($color-on-surface50, 0.8);

$color-surface-primary:       $color-on-surface25;
$color-surface-secondary:     $color-on-surface50;
$color-surface-white:         $color-on-surface0;
$color-surface-quarternary:   $color-on-surface800;

$color-semantic-alert:    #e82b37FF;
$color-semantic-warning:  #ec6513FF;
$color-semantic-positive: #37d002FF;
$color-semantic-info:     #2d71d7FF;

$color-footer: #00271d;


:root{    
    --color-primary: #{$color-primary};
    --color-secondary: #{$color-secondary};
    --color-tertiary: #{$color-tertiary};
    --color-quarternary: #{$color-quarternary};

    --color-hover-primary: #{$color-hover-primary};
    --color-hover-secondary: #{$color-hover-secondary};

    --color-on-surface0: #{$color-on-surface0};
    --color-on-surface25: #{$color-on-surface25};
    --color-on-surface50: #{$color-on-surface50};
    --color-on-surface100: #{$color-on-surface100};
    --color-on-surface200: #{$color-on-surface200};
    --color-on-surface300: #{$color-on-surface300};
    --color-on-surface400: #{$color-on-surface400};
    --color-on-surface500: #{$color-on-surface500};
    --color-on-surface600: #{$color-on-surface600};
    --color-on-surface700: #{$color-on-surface700};
    --color-on-surface750: #{$color-on-surface750};
    --color-on-surface800: #{$color-on-surface800};
    --color-on-surface850: #{$color-on-surface850};
    --color-on-surface900: #{$color-on-surface900};

    --color-disabled-primary: #{$color-disabled-primary};
    --color-disabled-secondary: #{$color-disabled-secondary};
    --color-disabled-tertiary: #{$color-disabled-tertiary};

    --color-text-primary: #{$color-text-primary};
    --color-font: #{$color-font};

    --color-background-primary: #{$color-background-primary};

    --color-transparent-primary: #{$color-transparent-primary};
    --color-transparent-secondary: #{$color-transparent-secondary};
    --color-transparent-tertiary: #{$color-transparent-tertiary};
    --color-transparent-backdrop: #{$color-transparent-backdrop};

    --color-surface-primary: #{$color-surface-primary};
    --color-surface-secondary: #{$color-surface-secondary};
    --color-surface-white: #{$color-surface-white};
    --color-surface-quarternary: #{$color-surface-quarternary};

    --color-semantic-alert: #{$color-semantic-alert};
    --color-semantic-warning: #{$color-semantic-warning};
    --color-semantic-positive: #{$color-semantic-positive};
    --color-semantic-info: #{$color-semantic-info};
}



//ENTFERNEN ACHTUNG wird teilweise zB noch in Mount Kuzu verwendet

// ALTE CI

// Primary Colors
// $color-green: #4ba82e !default;
// $color-green-rgb: rgb(75,168,46) !default;
$color-green: $color-primary !default;

$color-electric-green: $color-secondary !default;
$color-emerald-green: $color-tertiary !default;

$color-white: #fff !default;
$color-white-rgb: rgb(255,255,255) !default;

$color-black: #000 !default;
$color-black-rgb: rgb(0,0,0) !default;

$color-grey: #4a4a4a !default;
$color-grey-rgb: rgb(74, 74, 74) !default;

$color-darkgrey: #373737 !default;
$color-darkgrey-rgb: rgb(55, 55, 55) !default;


// Blue Color Scheme
$color-blue-scheme-1: #0090d7 !default;
$color-blue-scheme-1-rgb: rgb(0,144,215) !default;

$color-blue-scheme-2: #7ec9f1 !default;
$color-blue-scheme-2-rgb: rgb(126,201,241) !default;

$color-blue-scheme-3: #004f76 !default;
$color-blue-scheme-3-rgb: rgb(0,79,118) !default;

$color-blue-scheme-4: #777777 !default;
$color-blue-scheme-4-rgb: rgb(119,119,119) !default;

$color-blue-scheme-5: #4a4a4a !default;
$color-blue-scheme-5-rgb: rgb(74,74,74) !default;

$color-blue-scheme-6: #b8b8b8 !default;
$color-blue-scheme-6-rgb: rgb(184,184,184) !default;


// Red Color Scheme
$color-red-scheme-1: #e62336 !default;
$color-red-scheme-1-rgb: rgb(230,35,54) !default;

$color-red-scheme-2: #b00835 !default;
$color-red-scheme-2-rgb: rgb(176,8,53) !default;

$color-red-scheme-3: #ea5167 !default;
$color-red-scheme-3-rgb: rgb(234,81,103) !default;

$color-red-scheme-4: #e5d1a2 !default;
$color-red-scheme-4-rgb: rgb(229,209,162) !default;

$color-red-scheme-5: #878787 !default;
$color-red-scheme-5-rgb: rgb(135,135,135) !default;

$color-red-scheme-6: #cecece !default;
$color-red-scheme-6-rgb: rgb(206,206,206) !default;


// Yellow Color Scheme
$color-yellow-scheme-1: #d3da44 !default;
$color-yellow-scheme-1-rgb: rgb(211,218,68) !default;

$color-yellow-scheme-2: #a2c617 !default;
$color-yellow-scheme-2-rgb: rgb(162,198,23) !default;

$color-yellow-scheme-3: #ffdf43 !default;
$color-yellow-scheme-3-rgb: rgb(255,223,67) !default;

$color-yellow-scheme-4: #fff374 !default;
$color-yellow-scheme-4-rgb: rgb(255,243,116) !default;

$color-yellow-scheme-5: #b2b2b2 !default;
$color-yellow-scheme-5-rgb: rgb(178,178,178) !default;

$color-yellow-scheme-6: #9d9d9d !default;
$color-yellow-scheme-6-rgb: rgb(157,157,157) !default;


// Turquoise Color Scheme
$color-turquoise-scheme-1: #15af97 !default;
$color-turquoise-scheme-1-rgb: rgb(21,175,151) !default;

$color-turquoise-scheme-2: #008a83 !default;
$color-turquoise-scheme-2-rgb: rgb(0,138,131) !default;

$color-turquoise-scheme-3: #76b4af !default;
$color-turquoise-scheme-3-rgb: rgb(118,180,175) !default;

$color-turquoise-scheme-4: #74a3a1 !default;
$color-turquoise-scheme-4-rgb: rgb(116,163,161) !default;

$color-turquoise-scheme-5: #b2b2b2 !default;
$color-turquoise-scheme-5-rgb: rgb(178,178,178) !default;

$color-turquoise-scheme-6: #706f6f !default;
$color-turquoise-scheme-6-rgb: rgb(112,112,112) !default;



// Helper Colors
$color-white-alt: #f3f3f3 !default;
$color-white-alt-rgb: rgb(243,243,243) !default;

$color-white-alt-2: #f8f8f8 !default;
$color-white-alt-2-rgb: rgb(248,248,248) !default;

$color-light-grey: $color-blue-scheme-6 !default;
$color-light-grey-rgb: $color-blue-scheme-6-rgb !default;

$color-grey: $color-blue-scheme-5 !default;
$color-grey-rgb: $color-blue-scheme-5-rgb !default;

$color-dark-grey: #373737 !default; //zB Footer
$color-dark-grey-rgb: rgb(55,55,55) !default;

$color-green-light: #e5f5e0 !default;
$color-green-light-rgb: rgb(229,245,224) !default;



:export {
    color-primary: $color-primary;
    color-secondary: $color-secondary;
    color-tertiary: $color-tertiary;       
    color-quarternary: $color-quarternary;
    color-text-primary: $color-text-primary;
    color-hover-primary: $color-hover-primary;
    color-hover-secondary: $color-hover-secondary;

    color-on-surface900: $color-on-surface900;
    color-on-surface850: $color-on-surface850;
    color-on-surface800: $color-on-surface800;
    color-on-surface750: $color-on-surface750;
    color-on-surface700: $color-on-surface700;
    color-on-surface600: $color-on-surface600;
    color-on-surface500: $color-on-surface500;
    color-on-surface400: $color-on-surface400;
    color-on-surface300: $color-on-surface300;
    color-on-surface200: $color-on-surface200;
    color-on-surface100: $color-on-surface100;
    color-on-surface50:  $color-on-surface50;
    color-on-surface25:  $color-on-surface25;
    color-on-surface0:   $color-on-surface0;

}
