
.tooltip {
    position: relative;
    cursor: pointer;
    opacity: 1;
    padding: 0;
    font-family: $font-family-skoda-next;
    font-weight: 300;
    z-index: 10;


    &:hover {

        .tooltip__content {
            display: block;
        }
    }

    &__content {
        display: none;
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        max-width: 25rem;
        min-width: 5rem;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        padding: 1rem;
        border-radius: .25rem;
        z-index: 10;
        
        background-color: $color-on-surface800;
        color: $color-text-white;

        &::before {
            position: absolute;
            top: -8px;
            left: 6px;
            content: "";
            width: 0; 
            height: 0; 
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $color-on-surface800;
        }
    }

    &--top {
        
        .tooltip {

            &__content {
                bottom: calc(100% + 8px);
                top: unset;

                &::before {
                    top:unset;
                    bottom: -8px;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid $color-on-surface800;
                    border-bottom: transparent;
                }
            }
        }
    }

 

}