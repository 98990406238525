//For Webkit supported Browser (Chrome)
::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
    cursor: pointer;
}

::-webkit-scrollbar-track {
    //background: $color-on-surface25;
    border-radius: .5rem;
    //margin-bottom: 2rem;
}

::-webkit-scrollbar-thumb {
    background: $color-on-surface600;
    border-radius: .5rem;
    cursor: pointer;
    
    &:hover {
        background: $color-on-surface600;
    }
}

//For not supported Browser (like Firefox)
* {
    scrollbar-width: thin;
    scrollbar-color: $color-on-surface600 $color-on-surface25;
  }