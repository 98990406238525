

.quicklink {
    color: $color-text-primary;
    background-color: $color-surface-white;
    width: 100%;
    display: flex;
    height: 4rem;
    padding: 0 2rem;
    justify-content: start;
    align-items: center;
    font-size: 1.25rem;
    font-weight: bold;
    transition: .5s ease;
    transition-property: background-color;
    position: relative;
    overflow: hidden;  
    
    color: $color-text-primary;
    background: linear-gradient(90deg, rgba($color-on-surface0, 1) 0%, rgba($color-on-surface0, 0) 100%), var(--background-image);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right 30% ;

    &:hover {
        outline: 1px solid $color-primary;
        background: linear-gradient(90deg, rgba($color-on-surface50, 1) 0%, rgba($color-on-surface50, 0) 100%), var(--background-image); 
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: right 30% ;

        &::after {
            background-color: $color-on-surface50;
        }
    
    }

    &::after {
        background-color: $color-on-surface0;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .sa-i {
        margin-top: -4px;
        margin-right: 4px;
    }

    &--rounded {
        border-radius: .25rem;
    }
}