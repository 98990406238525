.breadcrumb {
    list-style-type: none; 
    padding-inline-start: 0;
    display: flex;

    li {
        a, span {
            display: inline-flex;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.002em;
            color: rgb(90, 91, 92);
            cursor: pointer;

            &:hover {
                color: rgb(22, 23, 24);
                text-decoration: underline; 
            }
        }

        &::before {
            content: none;
            margin-left: 0;
            width: auto;
            font-size: inherit;
        }

        &:not(:first-child)::before {
            content: "/";
            display: inline-block;
            padding-right: .5rem;
            margin-left: .5rem;
            font-weight: 400;
            color: rgb(90, 91, 92);
        }

        .active {
            color: rgb(22, 23, 24)
        }
    }

    &--white {

        li {
            a{
                color: white;
                opacity: 0.75;

                &:hover {
                    color: white;
                    opacity: 1;
                    text-decoration: underline; 
                }
            }

            &:not(:first-child)::before {
                color: white;
                opacity: 0.75;
            }
    
            .active {
                color: white;
                opacity: 1;
            }
        }
    }
}