.trend {
    display: flex;
    align-items: center;
    gap: 1rem;

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    &__icon {
        font-size: 2.5rem;
    }

    &__info {
        display: flex;
        flex-direction: column;
        line-height: 1.5;
    }

    &__zusatz {
        float: right;
    }
}