.stoerer {

    color: $color-secondary;
    background-color: $color-tertiary;
    font-weight: 900;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1.2;

    padding: 14px calc(24px + 17px) 14px 24px;

    -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 17px) 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, calc(100% - 17px) 100%, 0% 100%);

    //border-radius: 5px;

    &--down {
        -webkit-clip-path: polygon(0 0, calc(100% - 17px) 0, 100% 100%, 0% 100%);
                clip-path: polygon(0 0, calc(100% - 17px) 0, 100% 100%, 0% 100%);
    }

    &--eyecatcher {
        padding: 10px calc(24px + 17px) 10px 16px;
        font-weight: normal;

        > div:first-of-type {
            font-weight: 900;
            font-size: 1.25rem;
        }

        > div:last-of-type {
            font-weight: normal;
            font-size: .75rem;
        }
    }


    /* Color Variations */
    &--light {
        color: $color-tertiary;
        background-color: $color-secondary;
    }

    &--dark {
        color: $color-secondary;
        background-color: $color-tertiary;
    }

    &--white {
        background-color: $color-background-primary;
        color: $color-tertiary;
    }

    &--grey{
        color: $color-tertiary;
        background-color: $color-surface-primary;
    }

    &--blue{
        color: $color-text-white;
        background-color: $color-semantic-info;
    }

    &--compact {
        padding: .5rem calc(1rem + 17px) .5rem 1rem;
    }

}