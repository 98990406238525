.banner {
    padding: 2rem;
    min-height: 230px;
    background-repeat: no-repeat; 
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;


    h4, h3, p {
        color: $color-text-white;
    }
    
    h3, h4 {
        margin-bottom: .25rem;
    }

    .stoerer {
        padding: .25rem .5rem;
        padding-right: 1.5rem;
        font-weight: normal;
        font-size: .8rem;
    }

    &--alt {
        h4, h3, p {
            color: $color-text-primary;
        }
    }
}