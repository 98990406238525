.form {
    

    &__control {
        height: 56px;
        background-color: $color-on-surface25;
        border-bottom: 1px solid $color-text-primary;
        display: flex;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        &:focus-within {
            border-bottom-color: $color-primary;

            .form__input {
                box-shadow: inset 0px -2px 0px 0px $color-primary;
            }
           
        }
    }

    // INPUT FELDER wie Textfelder
    &__input {
        outline: none;
        border: none;
        padding: 24px 16px 8px 16px;
        font-size: 16px;
        font-weight: 400;
        color: $color-text-primary;
        width: 100%;
        height: 100%;
        background-color: $color-on-surface25;
        transition: .3s ease;
        

        &:focus:invalid {
            //border-bottom: 4px solid $color-semantic-alert;
            box-shadow: inset 0px -3px 0px 0px $color-semantic-alert;

        }

    }

    &__input[type=password] {
        -webkit-text-security: dot;
    }

    &__input[disabled] {
       opacity: .5;
    }

    &__label {
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        pointer-events: none;
        height: 100%;
        font-size: 16px;
        color: $color-text-primary;
        font-weight: 300;
    }


    //Floating Labels z.B. Login
    &__floating {
        display: block;
        position: relative;
        width: 100%;

        .form__label {
            position: absolute;
            cursor: text;
            font-size: .75em;
            opacity: 1;
            transition: top .2s;
            top: -.5em;
            left: 0;
            z-index: 3;
            line-height: 1;
            padding: 1rem;
            background: transparent;

            &::after {
                content: "";
                display: block;
                position: absolute;
                background: transparent;
                height: 2px;
                top: 50%;
                left: -.2em;
                right: -.2em;
                z-index: -1;
            }
        }

        .form__input {
                

            &::-webkit-input-placeholder {
                opacity: .5;
                -webkit-transition: top .2s;
                transition: top .2s;
            }
                

            &::-moz-placeholder {
                opacity: .5;
                -moz-transition: top .2s;
                transition: top .2s;
            }
                

            &:-ms-input-placeholder {
                opacity: .5;
                -ms-transition: top .2s;
                transition: top .2s;
            }
                

            &::-ms-input-placeholder {
                opacity: .5;
                -ms-transition: top .2s;
                transition: top .2s;
            }
                

            &::placeholder {
                opacity: .5;
                transition: top .2s;
            }

            &:placeholder-shown:not(:focus)::-webkit-input-placeholder {
                opacity: 0;
            }

            &:placeholder-shown:not(:focus)::-moz-placeholder {
                opacity: 0;
            }

            &:placeholder-shown:not(:focus):-ms-input-placeholder {
                opacity: 0;
            }

            &:placeholder-shown:not(:focus)::-ms-input-placeholder {
                opacity: 0;
            }

            &:placeholder-shown:not(:focus)::placeholder {
                opacity: 0;
            }

            &:placeholder-shown:not(:focus) + * {
                font-size: 16px;
                top: .25em;
            }
        }


        &.form--outline {

            .form__label{
                top: -1.75em;

                &::after {
                    background-color: $color-surface-white;
                    height: 1rem;
                    top: 0.875rem;
                    left: 1em;
                    right: 1em;
                    z-index: -1;
                }
            }

            .form__input{
               
                padding: 1rem 1rem;

                &:placeholder-shown:not(:focus) + * {
                    font-size: 16px;
                    top: 0em;
                }

                &:focus + .form__label{
                    color: $color-primary;
                }
            }
        }

    }



    &__icon { 
    
        display: flex;
        align-items: center;
        padding: 16px;

        img {
            width: 20px;
            height: 20px;

        }

        + div .form__input, + div .form__label {
            padding-left: 0;
        }


    }


    &--outline {
        
        .form {
            &__input {
                border: 1px solid $color-on-surface100;
                border-radius: .25rem;
                background-color: inherit;

                &:hover {
                    border-color: $color-on-surface400;
                }


                &:focus {
                    outline-offset: -1px;
                    outline: 2px solid $color-primary;
                    box-shadow: none;
                }
            }
        }
    }
    
}





.form {

    &__select {

        position: relative;

        select {
            -webkit-appearance: none;
               -moz-appearance: none;
                    appearance: none;
            border: none;
            width: 100%;
            height: 56px;
            -moz-padding-start: 1rem;
            padding-left: 1rem;
            padding-top: 1rem;
            color: $color-text-primary;
            background: $color-on-surface25;
            outline: none;
            cursor: pointer;

            border-top-left-radius: .5rem;
            border-top-right-radius: .5rem;
            border-bottom: 1px solid $color-on-surface800;
            transition: .3s ease;


            &::-ms-expand {
                display: none;
            }

            option {
                background-color: transparent;
            }
        }

        &:focus-within {

            select {
                border-bottom-color: $color-primary;
                box-shadow: inset 0px -2px 0px 0px $color-primary;
            }
            
        
            &::after {
                content: "󿿭";
            }
        }

        &::after {
            font-family: $font-family-skoda-next-icon;
            content: "󿿫";
            font-size: 1.5rem;
            position: absolute;
            top: 50%;
            right: 1rem;
            color: $color-tertiary;
            transform: translateY(-50%);
            pointer-events: none;
        }

        .form__label {
            position: absolute;
            font-size: .75rem;
            left: 1rem;
            top: .4rem;
        }

        &--disabled {
            select {
                color: rgba($color-text-primary, .5);
            }

                &::after{
                    opacity: .25;
                }
        }
           
    }

}