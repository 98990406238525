// We define the mixins for two major breakpoints mobile and tablet
 
 // $breakpoint is the variable that can have several values
 @mixin devices ($breakpoint) { //the name of the mixin is devices
  
  @if $breakpoint == tablet {    
    @media only screen and (max-width: 992px) {
      @content;
    }
  }
 
  @if $breakpoint == mobile {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
}