
.road {

    &__subline {
        font-size: 1rem;
        line-height: 1;
        font-weight: 300;
        margin-bottom: .5rem;
    }

    &__headline {
        font-size: 2rem;
        line-height: 1;
        font-weight: 300;
        margin-bottom: 1rem;
    }

    &__image {
        height: 100%;
        display:flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            width: 100%;
            -o-object-fit: cover;
               object-fit: cover;
        }
    }

}

.kapitel {
    position: relative;
    padding-bottom: 2rem;
    transition: all .3s ease;
    height: 100%;
    cursor: pointer;

    a {
        color: $color-text-primary;
    }

    &__headline {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.5;
    }

    &__teaser {
        font-size: 1rem;
        font-weight: 300;
    }

    &__datum {
        font-size: .75rem;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
    }

    &__icons {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }

    &__image {
        aspect-ratio: 3 / 2;
        width: 100%;
        overflow: hidden;
        object-fit: cover;

        img {
            -o-object-fit: cover;
               object-fit: cover;
            -o-object-position: center;
               object-position: center;
               width: 100%;
               height: 100%;
            transition: transform .3s ease;
        }
    }
    

    &:hover {
        background-color: $color-on-surface100;
        outline: 1px solid $color-primary;

        .kapitel {
            &__image {
                img{
                    transform: scale(1.05);
                }
            }
        }
    }


    &--big {
        padding-bottom: 0;

        .kapitel {

            &__info {
                position: absolute;
                bottom: 1rem;
                left: 1rem;
                padding: 0 !important;
                color: $color-text-white;
                width: calc(100% - 2rem);
                z-index: 2;
            }

            &__datum {
                position: relative;
                left: unset;
                bottom: unset;
            }

            &__icons {
                bottom: 0;
                right: 0;
            }

            &__overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                background: linear-gradient(0deg, rgba(48,48,48,.8) 30%, rgba(57,57,57,0) 60%);
            }
        }
    }
}